.banner-container {
    background-color: rgba(0, 0, 0, 0.6);
    /* background-image: url('https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img/https://coopminga.com/wp-content/uploads/2022/03/Agencias-Minga.png'); */
    background-image: uri('./assets/Portada-boletos.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 60vh;
    height: 90vh;
    display: flex;
    justify-content: center;
    background-blend-mode: overlay;
    align-items: center;
}