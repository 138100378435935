body {
    background: white;
    width: 100%;
    height: 100%;
}

#prize-wrapper {
    position: absolute;
    /* text-align: center;
    align-content: center;
    align-items: center; */
    left: 70%;
    top: 10%;
}

.banner-container-lottery {
    background-color: rgba(0, 0, 0, 0.6);
    background-image: uri('./assets/Lottery_hero.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    /* min-height: 60vh;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center; */
}