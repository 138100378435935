body {
    background: white;
    width: 100%;
    height: 100%;
}

#canvas-wrapper {
    position: absolute;
    left: 42%;
    top: 42%;
    background: url('/public/images/blower.png');
    background-repeat: no-repeat;
    background-size: 73%;
    background-position: 0px 80px; 
    transform: translateY(-50%) translateX(-50%);
}

#canvas-wrapper:after {
    content: "";
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 87%;
    height: 87%;
    transform: translateY(-50%) translateX(-10%);
}
